@tailwind base;
@tailwind components;
@tailwind utilities;

/* div {
  border-radius: 10px;
} */

textarea:focus,
input.middle:focus {
  outline-width: 0;
}

textarea {
  resize: none;
}